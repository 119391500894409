import app from "../../main";

const onlineStatistics = {

    namespaced: true,

    state: {

    },

    getters: {},

    mutations: {

    },

    actions: {

        queryOnlineStatisticsByDay({commit}, data) {
            return app.$api.device.queryOnlineStatisticsByDay(data);
        },

        modifyRemark({commit}, data) {
            return app.$api.device.modifyRemark(data);
        },

        copyOwnerInfo({commit}, data) {
            return app.$api.device.copyOwnerInfo(data);
        }

    }

}

export default onlineStatistics;
